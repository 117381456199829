<template>
  <div class="row">
    <div class="col-xl-12">
      <button class="btn btn-secondary mb-3 w-100" @click="createContentGroup()">
        <i class="uil-plus"></i> İçerik Grubu Ekle
      </button>
    </div>
    <div class="col-xl-12 mb-2" v-for="(content, index) in form.contents">
      <ul class="c-curriculum" uk-accordion>
        <li class="uk-open">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <a class="uk-accordion-title" href="#">
                <i class="uil-folder"> </i>{{ content.name }}</a>
            </div>
            <a class="btn btn-info btn-sm" style="color: #fff" @click="selectPickerTrigger"
              :uk-toggle="'target: #modal-learning_' + content.id">
              <i class="uil-plus"> </i>İçerik Oluştur
            </a>
            <a class="btn btn-sm btn-danger" @click="deleteGroupById(content.id)">
              <i class="uil-trash"></i>
            </a>
          </div>
          <div class="uk-accordion-content">
            <ul class="sec-list" uk-sortable="handle: .uk-sortable-handle">
              <li v-for="(education, educationIndex) in content.educations">
                <div class="sec-list-item">
                  <div>
                    <i class="uil-list-ul uk-sortable-handle"></i>
                    <p>{{ education.name }}</p>
                    <label class="mb-0 mx-2" style="
                        font-size: 0.75rem;
                        background: #3e416d;
                        padding: 1px 5px;
                        border-radius: 3px;
                        color: white;
                      ">
                      {{ getContentTypeById(education.content_type_id).name }}
                    </label>
                  </div>
                  <div>
                    <div class="btn-act">
                      <a @click="deleteEducation(index, educationIndex)" uk-tooltip="title:Kaldır"><i
                          class="icon-feather-trash"></i></a>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </li>
      </ul>

      <div :id="'modal-learning_' + content.id" class="uk-modal addContentModal" uk-modal="">
        <div class="uk-modal-dialog bggray w-75 no-padding">
          <button class="uk-modal-close-default" type="button" uk-close></button>
          <div class="uk-modal-header">
            <h3 class="uk-modal-title">İçerik Ekle</h3>
          </div>
          <div class="uk-padding-large position-relative uk-grid" uk-grid="">
            <div class="uk-width-5-5@m">
              <div class="uk-card-default rounded p-4">
                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group row mb-2 input-checkbox">
                      <div class="col-md-12">
                        <label class="col-form-label" for="contentTypes">İçerik Türü</label>

                        <!-- <multiselect
                          v-model="content_type_id"
                          tag-placeholder="İçerik Türünü Seçin"
                          placeholder="İçerik Türünü Seçin"
                          label="name"
                          track-by="id"
                          selectedLabel="Seçilmiş"
                          selectLabel="Seçmek için enter'a basın"
                          deselectLabel="Kaldırmak için enter'a basın"
                          selectGroupLabel="Grup seçmek için enter'a basın"
                          deselectGroupLabel="Grubun seçimini kaldırmak için enter'a basın"
                          :options="formattedContentTypes"
                          :multiple="false"
                          :taggable="false"
                          :openDirection="'bottom'"
                          @input="changeOption(content_type_id)"
                        /> -->
                        <multiple-select-input :model.sync="content_type_id" :multiple="false" :required="false"
                          :searchable="false" :showLabels="false" :values="formattedContentTypes" :allowEmpty="false"
                          @select="content_type_id = $event; changeOption(content_type_id)" />
                      </div>
                    </div>
                    <hr />
                    <div class="form-group">
                      <label for="contentName">İçerik Adı</label>
                      <input type="text" id="contentName" class="form-control" v-model="data.name"
                        placeholder="İçerik adı giriniz." />
                    </div>
                    <div class="form-group d-flex align-items-center" v-if="scormArea">
                      <label class="mr-3">Scorm İçerik Skorunu Kullan:</label>
                      <toggle-button v-model="data.use_content_point" color="#ae71ff" />
                    </div>
                    <div class="form-group" v-if="!data.use_content_point">
                      <label for="score">İçerik Skoru</label>
                      <input type="number" id="score" class="form-control" v-model="data.score"
                        placeholder="İçerik skoru giriniz." />
                    </div>
                    <div class="form-group">
                      <label for="duration">Süre</label>
                      <input type="number" id="duration" class="form-control" v-model="data.duration"
                        placeholder="İçerik süresini giriniz." />
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label class="mr-3">Demo:</label>
                      <toggle-button v-model="is_demo" color="#ae71ff" />
                    </div>
                  </div>
                  <div class="col-md-8">
                    <h4>
                      <span v-if="content_type_id">
                        {{ getContentTypeById(content_type_id).name }}
                      </span>
                      Yükle
                    </h4>

                    <template v-if="imageArea">
                      <div class="uploadOuter" v-if="imageArea">
                        <label class="btn btn-info" for="UploadImageArea"><i class="uil-upload"></i>{{
                          $t("general.upload") }}</label>
                        <strong>{{ $t("general.or") }}</strong>
                        <span class="dragBox">
                          <i class="icon-feather-file-plus"></i>
                          {{ $t("general.doc_file_drag_drop") }}
                          <template v-if="content_type_id == 2 || 3 || 4">
                            <input id="UploadImageArea1" :accept="getContentTypeById(content_type_id).accept
                              " type="file" @change="dragNdropModal($event, content_type_id)" />
                          </template>
                          <template v-if="content_type_id == 5">
                            <input id="UploadImageArea2" :accept="getContentTypeById(content_type_id).accept
                              " type="file" @change="dragNdropModal($event, content_type_id)" />
                          </template>
                        </span>
                      </div>
                      <div id="preview" v-if="preview" class="filePreview" v-html="preview"></div>
                    </template>
                    <template v-if="scormArea">
                      <div class="uploadOuter" v-if="scormArea">
                        <label class="btn btn-info" for="UploadscormArea"><i class="uil-upload"></i>{{
                          $t("general.upload") }}</label>
                        <strong>{{ $t("general.or") }}</strong>
                        <span class="dragBox">
                          <i class="icon-feather-file-plus"></i>
                          {{ $t("general.doc_file_drag_drop") }}
                          <template v-if="content_type_id == 2 || 3 || 4">
                            <input id="UploadscormArea1" :accept="getContentTypeById(content_type_id).accept
                              " type="file" @change="dragNdropModal($event, content_type_id)" />
                          </template>
                          <template v-if="content_type_id == 5">
                            <input id="UploadscormArea2" :accept="getContentTypeById(content_type_id).accept
                              " type="file" @change="dragNdropModal($event, content_type_id)" />
                          </template>
                        </span>
                      </div>
                      <div id="preview" v-if="preview" class="filePreview" v-html="preview"></div>
                    </template>

                    <template v-if="urlArea">
                      <div class="form-group">
                        <label for="url">Bağlantı Adresi</label>
                        <input type="text" v-model="data.url" id="url" class="form-control" />
                      </div>
                    </template>

                    <template v-if="videoArea">
                      <div class="row">
                        <div class="col-12">
                          <div class="uploadOuter">
                            <label for="uploadFileVideo" class="btn btn-info"><i class="uil-upload"></i>{{
                              $t("general.upload") }}</label>
                            <strong>{{ $t("general.or") }}</strong>
                            <span class="dragBox">
                              <i class="icon-feather-file-plus"></i>
                              {{ $t("general.video_file_drag_drop") }}
                              <input :accept="getContentTypeById(content_type_id).accept
                                " type="file" @change="dragNdropVideo($event)" id="uploadFileVideo" />
                            </span>
                          </div>
                        </div>
                        <div class="col-12">
                          <div v-if="videoPreview" id="preview" v-html="videoPreview"></div>
                        </div>
                      </div>
                    </template>

                    <template v-if="audioArea">
                      <div class="row">
                        <div class="col-12">
                          <div class="uploadOuter">
                            <label for="uploadFileAudio" class="btn btn-info"><i class="uil-upload"></i>{{
                              $t("general.upload") }}</label>
                            <strong>{{ $t("general.or") }}</strong>
                            <span class="dragBox">
                              <i class="icon-feather-file-plus"></i>
                              {{ $t("general.audio_file_drag_drop") }}
                              <input :accept="getContentTypeById(content_type_id).accept
                                " type="file" @change="dragNdropAudio($event)" id="uploadFileAudio" />
                            </span>
                          </div>
                        </div>
                        <div class="col-12">
                          <div v-if="audioPreview" id="preview" v-html="audioPreview"></div>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <div class="uk-card-header">
                <div class="float-right">
                  <button @click="closeModal" class="
                      uk-button uk-button-outline-default
                      mr-2
                      small
                      closeButton
                    " type="button">
                    <i class="icon-arrow-left"></i> Geri Dön
                  </button>
                  <button @click="setData(content.id)" class="uk-button uk-button-primary small closeButton"
                    type="button">
                    <i class="icon-feather-save"></i> {{ $t("general.save") }}

                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "@/core/services";
import moment from "moment";
import content_categories_module, {
  GET_FILE_TYPES,
  FILE_TYPES_URL,
  MODULE_NAME as CONTENT_CATEGORIES_MODULE,
  FILE_TYPES,
} from "@/core/services/store/content_categories.module";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import MultipleSelectInput from "@/assets/components/inputs/MultipleSelectInput";
const _CONTENT_CATEGORIES_MODULE = CONTENT_CATEGORIES_MODULE;

export default {
  name: "CatalogContentAdd",
  components: { Multiselect, MultipleSelectInput },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }
    registerStoreModule(_CONTENT_CATEGORIES_MODULE, content_categories_module);
  },
  data() {
    return {
      form: {
        contents: [],
      },
      preview: null,
      is_demo: false,
      content_type_id: null,
      data: {
        use_content_point: false,
      },
      imageArea: false,
      scormArea: false,
      urlArea: false,
      videoArea: false,
      audioArea: false,
      videoPreview: null,
      audioPreview: null,
    };
  },
  computed: {
    content_types: {
      get() {
        return store.getters[_CONTENT_CATEGORIES_MODULE + "/" + FILE_TYPES];
      },
      set(value) { },
    },
    formattedContentTypes: {
      get() {
        let results = [];

        if (this.content_types === null) return [];
        this.content_types.forEach((item) => {
          if (item) {
            results.push({
              icon: item.icon,
              name: item.name,
              id: item.id,
              key: item.key_name,
              accept: item.accept,
            });
          }
        });
        return results;
      },
      set(value) { },
    },
  },
  methods: {
    createContentGroup() {
      Swal.fire({
        title: "İçerik Adı",
        input: "text",
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonText: "Oluştur",
        confirmButtonColor: "#ae71ff",
        cancelButtonText: "Vazgeç",
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          if (result.value.length != 0) {
            let length = this.form.contents.length;
            this.form.contents.push({
              id: length + 1,
              name: result.value,
              educations: [],
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Hata...",
              text: "Lütfen bir değer giriniz!",
              confirmButtonColor: "#ae71ff",
              confirmButtonText: "Tamam",
            });
          }
        }
      });
      this.setParent();
    },
    closeModal() {
      $(".uk-modal-close-default").click();
    },
    selectPickerTrigger() {
      setTimeout(function () {
        $(".selectpickerFileCategory").selectpicker();
      }, 300);
    },
    dragNdropModal(event, file_type) {
      let file = event.target.files[0];

      if (
        this.compareTypeCompatibality(
          file,
          this.getContentTypeById(this.content_type_id).accept
        )
      ) {
        this.data.media = file;

        this.preview =
          '<div class="file-info">' +
          "<div>Format: <span>" +
          file.type +
          "</span></div>" +
          "<div>Doküman Adı: <span>" +
          file.name +
          "</span></div>" +
          "<div>Boyut: <span>" +
          this.bytesToSize(file.size) +
          "</span></div>" +
          "</div><br>";
        if (!this.scormArea) {
          this.preview =
            this.preview +
            '<div><img src="' +
            URL.createObjectURL(file) +
            '" /></div>';
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Hata...",
          text: "Lütfen geçerli bir döküman seçiniz.",
          confirmButtonColor: "#ae71ff",
          confirmButtonText: "Tamam",
        });
      }
    },
    dragNdropVideo(event) {
      let self = this;
      let file = event.target.files[0];

      if (
        this.compareTypeCompatibality(
          file,
          this.getContentTypeById(this.content_type_id).accept
        )
      ) {
        self.data.media = file;

        let html =
          '<div class="file-info">' +
          "<div>Format: <span>" +
          file.type +
          ' <i class="uil-video" style="font-size: 14px"></i></span></div>' +
          "<div>Video Dosyası Adı: <span>" +
          file.name +
          "</span></div>" +
          "<div>Boyut: <span>" +
          self.bytesToSize(file.size) +
          "</span></div>" +
          "</div>";

        self.videoPreview = html;
      } else {
        Swal.fire({
          icon: "error",
          title: "Hata...",
          text: "Lütfen geçerli bir video seçiniz.",
          confirmButtonColor: "#ae71ff",
          confirmButtonText: "Tamam",
        });
      }
    },
    compareTypeCompatibality(file, acceptableType) {
      return (
        acceptableType.includes(file.type) ||
        acceptableType.includes(file.name.split(".").pop()) ||
        (acceptableType.split("/")[0] == file.type.split("/")[0] &&
          file.type.split("/")[0].toLowerCase() != "application")
      );
    },
    dragNdropAudio(event) {
      let self = this;
      let file = event.target.files[0];

      if (this.compareTypeCompatibality(file, this.getContentTypeById(this.content_type_id).accept)) {
        self.data.media = file;

        let html =
          '<div class="file-info">' +
          "<div>Format: <span>" +
          file.type +
          ' <i class="uil-microphone" style="font-size: 14px"></i></span></div>' +
          "<div>Ses Dosyası Adı: <span>" +
          file.name +
          "</span></div>" +
          "<div>Boyut: <span>" +
          self.bytesToSize(file.size) +
          "</span></div>" +
          "</div>";

        self.audioPreview = html;
      } else {
        Swal.fire({
          icon: "error",
          title: "Hata...",
          text: "Lütfen geçerli bir ses dosyası seçiniz.",
          confirmButtonColor: "#ae71ff",
          confirmButtonText: "Tamam",
        });
      }
    },
    getContentTypeById(id) {
      if (typeof id.id !== "undefined") {
        id = id.id;
      }
      let ContentType;
      this.formattedContentTypes.forEach((item) => {
        if (item.id === id) {
          ContentType = item;
        }
      });

      return ContentType;
    },
    changeOption(id) {
      let self = this;
      let ContentType = this.getContentTypeById(id);
      if (typeof ContentType.key !== "undefined") {
        self.imageArea = false;
        self.urlArea = false;
        self.videoArea = false;
        self.audioArea = false;
        self.scormArea = false;
        self.preview = null;
        self.videoPreview = null;
        self.audioPreview = null;
        switch (ContentType.key) {
          case "documents.visual_document":
          case "documents.word_document":
          case "documents.pdf_document":
          case "documents.powerpoint_document":
            self.imageArea = true;
            break;
          case "documents.scorm_package":
            self.scormArea = true;
            break;
          case "documents.video_url":
          case "documents.url":
          case "documents.anzera_education":
          case "documents.webinar":
          case "documents.classroom_education":
            self.urlArea = true;
            break;
          case "documents.video_document":
            self.videoArea = true;
            break;
          case "documents.audio_document":
            self.audioArea = true;
            break;
        }
      }
    },
    setParent() {
      this.$emit("getData", this.form.contents);
    },
    setData(content_id) {
      let self = this;

      self.form.contents.forEach((item, index) => {
        if (item.id == content_id) {
          let data = self.data;
          data.is_demo = self.is_demo == true ? 1 : 0;
          data.content_type_id = self.content_type_id;
          self.form.contents[index].educations.push(data);
        }
      });

      self.preview = null;
      self.audioPreview = null;
      self.videoPreview = null;

      self.is_demo = false;
      self.data = {};
      self.setParent();
      self.closeModal();
    },
    deleteEducation(content_index, education_index) {
      this.form.contents[content_index].educations.splice(education_index, 1);
      this.setParent();
    },
    deleteGroupById(content_id) {
      let self = this;
      self.form.contents.forEach((item, index) => {
        if (item.id == content_id) {
          self.form.contents.splice(index, 1);
        }
      });
      this.setParent();
    },
  },
  mounted() {
    if (!this.CatalogTrainingVisible) {
      this.$router.push({ name: "company.dashboard" });
    }
    store.dispatch(_CONTENT_CATEGORIES_MODULE + "/" + GET_FILE_TYPES, {
      url: FILE_TYPES_URL,
    });
  },
};
</script>
<style scoped>
input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}

.no-padding {
  padding: 0;
}

.btn-danger {
  background-color: #ed5252 !important;
  border-color: #ed5252 !important;
  color: #fff !important;
}
</style>



<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect .multiselect__input {
  border: unset !important;
  border: 0 !important;
  box-shadow: none;
  outline: none;
}
</style>
<style>
.multiselect__tag {
  color: white;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #3e416d;
}

.multiselect__tag-icon {
  background-color: transparent;
  line-height: 2.2;
}

.multiselect__tag-icon:after {
  color: #fff;
}

.multiselect__tag-icon:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.multiselect__input {
  border: unset !important;
}

.multiselect__tags .multiselect__input {
  border: unset !important;
}
</style>
